import React, { useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { useSelector } from 'react-redux';
import CustomPlayerVideoJS from '../../../../wrappers/CustomPlayerVideoJS';
import SyncWithElapsedTimeStrategy from '../../../../strategies/SyncWithElapsedTimeStrategy';
import Events from '../../../../constants/events';
import MainCameraView from '../MainCameraView';
import NoSyncStrategy from '../../../../strategies/NoSyncStrategy';
import cameraOptions from '../../../../types/cameraOptions';
import cameraObject from '../../../../types/cameraObject';

export function MainCameraIOS(props) {
  const customPlayerRef = useRef(null);
  const playersRef = useRef([null, null, null]);
  const [customPlayers, setCustomPlayers] = useState([null, null, null]);
  const { options, onReady, cameras, activeCameraId } = props;

  const [isReady, setIsReady] = useState(false);

  const isLive = useSelector((state) => state.player.isLive);

  useEffect(() => {
    customPlayerRef.current = null;
    setCustomPlayers([null, null, null]);
    setIsReady(false);
  }, [cameras]);

  useEffect(() => {
    if (!customPlayers.some((player) => player == null)) {
      setIsReady(true);
      // eslint-disable-next-line prefer-destructuring
      customPlayerRef.current = customPlayers[getCameraIndexById(activeCameraId)];
      setSyncStrategy(customPlayerRef.current);
      customPlayerRef.current.unmute();
      onReady(customPlayerRef.current);
    }
  }, [customPlayers]);

  function setSyncStrategy(customPlayer) {
    const strategy = isLive ? new SyncWithElapsedTimeStrategy() : new NoSyncStrategy();
    customPlayer.setSyncStrategy(strategy);
  }

  const onPlayerReady = (player, index) => {
    if (player) {
      playersRef.current[index] = player;
      const customPlayer = new CustomPlayerVideoJS(player, cameras[index].cameraId, true);
      customPlayer.mute();

      setCustomPlayers((prevState) => {
        const newState = [...prevState];
        newState[index] = customPlayer;
        return newState;
      });
    }
  };

  useEffect(() => {
    if (!isReady) return;

    const activeCameraIndex = getCameraIndexById(activeCameraId);
    const newCustomPlayer = customPlayers[activeCameraIndex];

    if (customPlayerRef.current) {
      initNewPlayerInsteadOfCurrent(newCustomPlayer);
    }
  }, [activeCameraId]);

  function getCameraIndexById(cameraId) {
    const index = cameras
      .findIndex((camera) => camera.cameraId === cameraId);
    return index > 0 ? index : 0;
  }

  function destroyOldPlayer() {
    customPlayerRef.current.mute();
    customPlayerRef.current.setIsPlaying(false);
  }

  function initNewPlayerInsteadOfCurrent(customPlayer) {
    customPlayer.one(Events.SEEKED, () => {
      setSyncStrategy(customPlayer);
      onReady(customPlayer);
    });
    const isPaused = customPlayerRef.current.isPaused();
    destroyOldPlayer();
    customPlayer.setIsPlaying(!isPaused, true);
    customPlayer.setCurrentTime(customPlayerRef.current.getCurrentTime());
    customPlayerRef.current = customPlayer;
    customPlayer.unmute();
  }

  const memoizedCameras = useMemo(() => (
    <div>
      {cameras.map((camera, index) => (
        <MainCameraView
          key={camera.cameraId}
          isVisible={camera.cameraId === activeCameraId}
          options={{ ...options, sources: [camera.source] }}
          onReady={(player) => onPlayerReady(player, index)}
        />
      ))}
    </div>
  ), [cameras, options, activeCameraId]);

  return memoizedCameras;
}

export default MainCameraIOS;

MainCameraIOS.propTypes = {
  options: cameraOptions.isRequired,
  onReady: func.isRequired,
  cameras: arrayOf(cameraObject),
  activeCameraId: string.isRequired,
};
