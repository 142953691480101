import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Room from '../Room';
import '../../EpisodePlayer.scss';
import SyncWithPlayerOnDemandStrategy from '../../strategies/SyncWithPlayerOnDemandStrategy';
import { setPlayedTime } from '../../../../reducers/player';
import Events from '../../constants/events';

function EpisodePlayerOnDemand({ mainConfig, secondaryConfigs }) {
  const [mainPlayer, setMainPlayer] = useState(null);
  const [secondaryPlayers, setSecondaryPlayers] = useState([null, null, null]);
  const [mainPlayerReady, setMainPlayerReady] = useState(false);

  const dispatch = useDispatch();

  const memoizedSecondaryVideos = useMemo(() => (mainPlayerReady ? secondaryConfigs
    .map((config, i) => (
      <Room
        roomId={config.roomId}
        key={config.roomId}
        onReady={(player) => onSecondaryPlayerReady(player, i)}
      />
    )) : null), [secondaryConfigs, mainPlayerReady]);

  const memoizedPrimaryVideo = useMemo(() => (mainConfig ? (
    <Room
      roomId={mainConfig.roomId}
      onReady={onMainPlayerReady}
      isMain
    />
  ) : null), [mainConfig]);

  useEffect(() => {
    if (mainPlayer) {
      setMainPlayerReady(true);

      mainPlayer.on(Events.TIME_UPDATE, () => {
        dispatch(setPlayedTime(mainPlayer.getCurrentTime()));
      });

      secondaryPlayers.forEach((player) => {
        if (player) {
          player.destroySyncStrategy();
          player.setSyncStrategy(new SyncWithPlayerOnDemandStrategy(mainPlayer));
        }
      });
    }
  }, [mainPlayer]);

  function onSecondaryPlayerReady(player, index) {
    if (mainPlayer && !mainPlayer.isDisposed()) {
      player.setSyncStrategy(new SyncWithPlayerOnDemandStrategy(mainPlayer));
    }

    setSecondaryPlayers((prevState) => {
      const newState = [...prevState];
      newState[index] = player;
      return newState;
    });
  }

  function onMainPlayerReady(player) {
    setMainPlayer(player);
  }

  return (
    <div>
      <div className="videos" data-testid="videos">
        {memoizedPrimaryVideo}
        <div className="secondary">
          {memoizedSecondaryVideos}
        </div>
      </div>
    </div>
  );
}

export default EpisodePlayerOnDemand;

EpisodePlayerOnDemand.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mainConfig: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  secondaryConfigs: PropTypes.array,

};

EpisodePlayerOnDemand.defaultProps = {
  mainConfig: null,
  secondaryConfigs: [],
};
