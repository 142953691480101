export const parseToMs = (date) => new Date(date).getTime();

export const formTimerOutput = (time) => {
  if (time > 0) {
    const date = new Date(time);
    const year = (date.getUTCFullYear() - 70).toString().slice(-2);
    const month = `0${date.getUTCMonth() + 0}`.slice(-2);
    const day = `0${date.getUTCDate() - 1}`.slice(-2);
    const hour = `0${date.getUTCHours()}`.slice(-2);
    const minute = `0${date.getUTCMinutes()}`.slice(-2);
    const second = `0${date.getUTCSeconds()}`.slice(-2);
    const addDateStringPart = (number, str) => {
      const parsedNumber = parseInt(number, 10);
      return parsedNumber
        ? `${number} ${parsedNumber > 1 ? `${str}s ` : `${str} `}`
        : '';
    };
    return `${
      addDateStringPart(year, 'Year')
      + addDateStringPart(month, 'Month')
      + addDateStringPart(day, 'Day')
      + hour
    }:${minute}:${second}`;
  }
  return null;
};
