import React from 'react';

import Home from './Home';
import Episode from './Episode';
import EpisodeWatch from './EpisodeWatch';
import About from './About';
import FAQ from './FAQ';

const pages = [
  {
    path: '',
    element: <Home />,
    title: 'home',
  },
  {
    path: 'episode/:episodeId',
    element: <Episode />,
    title: 'episode',
  },
  {
    path: 'episode/:episodeId/watch',
    element: <EpisodeWatch />,
    title: 'episode-watch',
  },
  {
    path: 'about',
    element: <About />,
    title: 'about',
  },
  {
    path: 'faq',
    element: <FAQ />,
    title: 'faq',
  },
];

export default pages;
