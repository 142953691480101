import videojs from 'video.js';
import CustomPlayer from './CustomPlayer';

export default class CustomPlayerVideoJS extends CustomPlayer {
  constructor(player, playerId, enableLogging = false) {
    super(player, playerId, enableLogging);

    this.isUnmuteButtonShown = false;
    this.isPlayButtonShown = false;
  }

  on(eventName, callback) {
    this.originalPlayer.on(eventName, callback);
  }

  off(eventName, callback) {
    this.originalPlayer.off(eventName, callback);
  }

  one(eventName, callback) {
    this.originalPlayer.one(eventName, callback);
  }

  getCurrentTime() {
    return this.originalPlayer.currentTime();
  }

  setCurrentTime(value) {
    this.originalPlayer.currentTime(value);
  }

  isPaused() {
    return this.originalPlayer.paused();
  }

  trigger(eventName) {
    super.trigger(eventName);
    this.originalPlayer.trigger(eventName);
  }

  unmute() {
    super.unmute();
    this.originalPlayer.volume(1);
  }

  mute() {
    super.mute();
    this.originalPlayer.volume(0);
  }

  handleNotAllowedError() {
    if (!this.originalPlayer.muted()) {
      this.originalPlayer.muted(true);
      this.setIsPlaying(true);
      this.showUnmuteButton();
    } else {
      this.showPlayButton();
    }
  }

  showPlayButton() {
    if (this.isPlayButtonShown) return;

    this.hideUnmuteButton();

    const button = this.createCustomButton('PLAY', () => {
      this.play();
      this.hidePlayButton(button);
    });

    this.originalPlayer.addChild(button);
    this.isPlayButtonShown = true;
  }

  hidePlayButton(button) {
    if (!this.isPlayButtonShown) return;
    this.originalPlayer.removeChild(button);
    this.isPlayButtonShown = false;
  }

  showUnmuteButton() {
    if (this.isUnmuteButtonShown) return;

    this.hidePlayButton();

    const button = this.createCustomButton('UNMUTE', () => {
      this.originalPlayer.muted(false);
      this.hideUnmuteButton(button);
    });

    this.originalPlayer.addChild(button);
    this.isUnmuteButtonShown = true;
  }

  hideUnmuteButton(button) {
    if (!this.isUnmuteButtonShown) return;
    this.originalPlayer.removeChild(button);
    this.isUnmuteButtonShown = false;
  }

  createCustomButton(controlText, clickHandler) {
    const Button = videojs.getComponent('Button');

    return new Button(this.originalPlayer, {
      controlText,
      clickHandler,
      className: 'vjs-custom-button',
    });
  }

  hideControls() {
    this.originalPlayer.addClass('vjs-live-mode-controls');

    this.originalPlayer.on('contextmenu', (event) => {
      event.preventDefault();
    });
  }
}
