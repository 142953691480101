import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Room from '../Room';
import '../../EpisodePlayer.scss';
import LiveModeControls from '../LiveModeControls';
import { setPlayedTime, setVideoEnded } from '../../../../reducers/player';
import { setStartTime, setTimePassedFromEpisodeStart } from '../../services/TimeSync';
import { getServerTime } from '../../../../api/services/Timer';

function EpisodePlayerLive({ mainConfig, secondaryConfigs }) {
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [playerStartTime, setPlayerStartTime] = useState(0);
  const [mainPlayer, setMainPlayer] = useState(null);
  const [secondaryPlayers, setSecondaryPlayers] = useState([null]);
  const [secondaryPlayersReady, setSecondaryPlayersReady] = useState(false);
  const [mainPlayerReady, setMainPlayerReady] = useState(false);

  const isPlaying = useSelector((state) => state.player.isPlaying);
  const episodeStartTime = useSelector((state) => state.episode.startTime);
  const dispatch = useDispatch();

  const memoizedSecondaryVideos = useMemo(
    () => secondaryConfigs.map((config, i) => (
      <Room
        roomId={config.roomId}
        key={config.roomId}
        onReady={(player) => onSecondaryPlayerReady(player, i)}
      />
    )),
    [secondaryConfigs, isPlaying],
  );

  const memoizedPrimaryVideo = useMemo(
    () => (mainConfig ? (
      <Room
        roomId={mainConfig.roomId}
        onReady={onMainPlayerReady}
        isMain
      />
    ) : null),
    [mainConfig, isPlaying],
  );

  useEffect(() => {
    if (!secondaryPlayers.some((player) => player == null)) {
      setSecondaryPlayersReady(true);
    }
  }, [secondaryPlayers]);

  useEffect(() => {
    if (mainPlayer) {
      setMainPlayerReady(true);

      mainPlayer.on('timeupdate', () => {
        setPlayedSeconds(mainPlayer.getCurrentTime());
        dispatch(setPlayedTime(mainPlayer.getCurrentTime()));
      });

      mainPlayer.on('ended', () => {
        onVideoEnded();
      });

      if (isPlaying) {
        mainPlayer.setIsPlaying(true);
      }
    }
  }, [mainPlayer]);

  useEffect(() => {
    const delayStart = new Date().getTime();
    const startTime = performance.now();
    setStartTime(startTime);
    setPlayerStartTime(startTime);
    getServerTime().then(({ currentTime }) => {
      const delay = new Date().getTime() - delayStart;
      setTimePassedFromEpisodeStart({ currentTime, episodeStartTime, delay });
    });
  }, []);

  function onSecondaryPlayerReady(player, index) {
    setSecondaryPlayers((prevState) => {
      const newState = [...prevState];
      newState[index] = player;
      return newState;
    });
  }

  function onMainPlayerReady(player) {
    setMainPlayer(player);
  }

  function onVideoEnded() {
    dispatch(setVideoEnded());
  }

  useEffect(() => {
    if (!mainPlayer) return;

    if (isPlaying) {
      mainPlayer.setIsPlaying(true);
      secondaryPlayers
        .filter((player) => player !== null)
        .forEach((player) => player.setIsPlaying(true));
    } else {
      mainPlayer.setIsPlaying(false);
      secondaryPlayers
        .filter((player) => player !== null)
        .forEach((player) => player.setIsPlaying(false));
    }
  }, [isPlaying, mainPlayer, secondaryPlayers]);

  return (
    <div>
      <div className="videos" data-testid="videos">
        {memoizedPrimaryVideo}
        <div className="secondary">{memoizedSecondaryVideos}</div>
      </div>
      {mainPlayerReady && secondaryPlayersReady && (
        <LiveModeControls
          playedSeconds={playedSeconds}
          startTime={playerStartTime}
        />
      )}
    </div>
  );
}

export default EpisodePlayerLive;

EpisodePlayerLive.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mainConfig: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  secondaryConfigs: PropTypes.array,
};

EpisodePlayerLive.defaultProps = {
  mainConfig: null,
  secondaryConfigs: [],
};
