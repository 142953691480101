import React from 'react';
import './EvidencePopup.scss';
import { arrayOf, func, shape, string } from 'prop-types';
import FullscreenPopup from '../../../../FullscreenPopup';

export function EvidencePopup(props) {
  const { onClose, images } = props;
  return (
    <FullscreenPopup onClose={onClose}>
      {images.map((image) => <img key={image.src} src={image.src} alt="" />)}
    </FullscreenPopup>
  );
}

export default EvidencePopup;

EvidencePopup.propTypes = {
  images: arrayOf(shape({
    src: string,
  })),
  onClose: func,
};

EvidencePopup.defaultProps = {
  images: [],
  onClose: () => {},
};
