/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPlaying: false,
  isLive: true,
  isVideoEnded: false,
  isEpisodeShown: false, // TODO move to episode reducer?
  camerasPosition: {
    main: {},
    secondary: [],
  },
  mainCameraOptions: null,
  secondaryCameraOptions: null,
  roomsConfig: [],
  playedTime: 0,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    play(state) {
      state.isPlaying = true;
    },
    pause(state) {
      state.isPlaying = false;
    },
    togglePlay(state) {
      state.isPlaying = !state.isPlaying;
    },
    setPlayerMode(state, action) {
      state.isLive = action.payload !== 'ondemand';
    },
    setVideoEnded(state) {
      state.isVideoEnded = true;
    },
    showEpisode(state) {
      state.isEpisodeShown = true;
    },
    hideEpisode(state) {
      state.isEpisodeShown = false;
    },
    setCamerasPosition(state, action) {
      state.camerasPosition = action.payload;
    },
    setActiveCameraId(state, action) {
      const { roomId, activeCameraId } = action.payload;

      if (state.camerasPosition.main.roomId === roomId) {
        state.camerasPosition.main.activeCameraId = activeCameraId;
        return;
      }

      const secondaryCameraIndex = state.camerasPosition.secondary
        .findIndex((camera) => camera.roomId === roomId);

      state.camerasPosition.secondary[secondaryCameraIndex].activeCameraId = activeCameraId;
    },
    switchToMainCamera(state, action) {
      const { roomId } = action.payload;

      if (state.camerasPosition.main.roomId === roomId) {
        return;
      }

      const mainCameraState = state.camerasPosition.main;
      const secondaryCameraIndex = state.camerasPosition.secondary
        .findIndex((camera) => camera.roomId === roomId);

      state.camerasPosition.main = state.camerasPosition.secondary[secondaryCameraIndex];
      state.camerasPosition.secondary[secondaryCameraIndex] = mainCameraState;
    },
    setMainCameraOptions(state, action) {
      state.mainCameraOptions = action.payload;
    },
    setSecondaryCameraOptions(state, action) {
      state.secondaryCameraOptions = action.payload;
    },
    setRoomsConfig(state, action) {
      state.roomsConfig = action.payload;
    },
    setPlayedTime(state, action) {
      state.playedTime = action.payload;
    },
  },
});

export const {
  play,
  pause,
  togglePlay,
  setPlayerMode,
  setVideoEnded,
  showEpisode, // TODO move to episode reducer?
  hideEpisode, // TODO move to episode reducer?
  setCamerasPosition,
  setActiveCameraId,
  switchToMainCamera,
  setMainCameraOptions,
  setSecondaryCameraOptions,
  setRoomsConfig,
  setPlayedTime,
} = playerSlice.actions;

export default playerSlice.reducer;
