import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import { func, string } from 'prop-types';
import CustomPlayerVideoJS from '../../../../wrappers/CustomPlayerVideoJS';

import '../../Camera.scss';
import SyncWithPlayerStrategy from '../../../../strategies/SyncWithPlayerStrategy';
import Events from '../../../../constants/events';
import NoSyncStrategy from '../../../../strategies/NoSyncStrategy';
import cameraOptions from '../../../../types/cameraOptions';

export function MainCameraOnDemand(props) {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);
  const oldPlayerRef = useRef(null);
  const customPlayerRef = useRef(null);
  const oldCustomPlayerRef = useRef(null);
  const { options, onReady, roomId } = props;

  const [isBlurred] = useState(false);

  const onPlayerReady = (player) => {
    if (player) {
      const customPlayer = new CustomPlayerVideoJS(player, options.cameraId, true);

      // there is a player that we should sync with
      if (oldPlayerRef.current) {
        initNewPlayerInsteadOfCurrent(customPlayer);
      } else {
        customPlayer.setSyncStrategy(new NoSyncStrategy());
        customPlayer.unmute();
        onReady(customPlayer);
      }

      customPlayerRef.current = customPlayer;
    }
  };

  useEffect(() => {
    if (customPlayerRef.current) {
      customPlayerRef.current.mute();
    }
  }, [roomId]);

  useEffect(() => {
    if (!playerRef.current) {
      createPlayer();
    } else if (playerRef.current && !customPlayerRef.current) {
      // the player started initializing but is not ready yet
      playerRef.current.dispose();
      createPlayer();
    } else {
      destroyOldPlayer();
      assignOldPlayer();
      createPlayer();
    }
  }, [options]);

  function initNewPlayerInsteadOfCurrent(customPlayer) {
    // if we're on pause
    if (oldCustomPlayerRef.current.isPaused()) {
      // we shouldn't sync with any player, so we're already on track
      // set current time to be the same of old player
      customPlayer.setCurrentTime(oldCustomPlayerRef.current.getCurrentTime());
      const seekedHandler = () => {
        customPlayer.off(Events.SEEKED, seekedHandler);
        onReady(customPlayer);
        destroyOldPlayer();
        customPlayer.unmute();
      };
      customPlayer.on(Events.SEEKED, seekedHandler);
    } else {
      // the playback is in progress, so we should sync with it
      const caughtUpHandler = () => {
        // when we are synchronized with the old player we change the sync strategy
        customPlayer.setSyncStrategy(new NoSyncStrategy());
        customPlayer.unmute();
        onReady(customPlayer);
        destroyOldPlayer();
        customPlayer.off(Events.CAUGHT_UP, caughtUpHandler);
      };
      customPlayer.on(Events.CAUGHT_UP, caughtUpHandler);
      customPlayer.setSyncStrategy(new SyncWithPlayerStrategy(oldCustomPlayerRef.current));
      customPlayer.setIsPlaying(!oldCustomPlayerRef.current.isPaused());
    }
  }

  function assignOldPlayer() {
    oldPlayerRef.current = playerRef.current;

    oldCustomPlayerRef.current = customPlayerRef.current;
    customPlayerRef.current = null;
    oldCustomPlayerRef.current.playerId += '_old';

    document.getElementById(oldPlayerRef.current.id()).style.display = 'none';
  }

  function destroyOldPlayer() {
    const oldPlayer = oldPlayerRef.current;
    const oldCustomPlayer = oldCustomPlayerRef.current;

    if (oldPlayer) {
      oldPlayer.dispose();
      oldPlayerRef.current = null;
    }

    if (oldCustomPlayer) {
      oldCustomPlayer.dispose();
      oldCustomPlayerRef.current = null;
    }
  }

  function createPlayer() {
    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(
      document.createElement('video-js'),
    );

    // eslint-disable-next-line no-multi-assign
    const player = (playerRef.current = videojs(videoElement, options, () => {
      onPlayerReady(player);
    }));
  }

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="camera">
      <div ref={placeholderRef} />
      {isBlurred && <div className="blur-overlay" />}
    </div>
  );
}

export default MainCameraOnDemand;

MainCameraOnDemand.propTypes = {
  options: cameraOptions.isRequired,
  onReady: func.isRequired,
  roomId: string.isRequired,
};
