export const secondsToHms = (secs) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = Math.floor(secs % 60);
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

export const getFormattedEpisodeStartTime = (startTime) => {
  const date = new Date(startTime);
  const timePart = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    hour12: true,
    timeZoneName: 'short',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);

  const datePart = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date);

  return `${timePart} | ${datePart}`;
};
