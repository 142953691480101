import React from 'react';
import { bool, func } from 'prop-types';

export function EvidenceButton(props) {
  const { onClick, isEnabled } = props;
  return (
    <button
      type="button"
      data-testid="show-evidence-btn"
      disabled={!isEnabled}
      onClick={onClick}
    >
      Evidence
    </button>
  );
}

EvidenceButton.propTypes = {
  onClick: func.isRequired,
  isEnabled: bool,
};

EvidenceButton.defaultProps = {
  isEnabled: false,
};

export default EvidenceButton;
