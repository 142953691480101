import { MAX_ELAPSED_OFFSET, PUSH_FORWARD_MS, SYNC_ITERATIONS } from '../../config/video-sync-config';
import Events from '../../constants/events';
import { requestAnimFrame } from '../../services/TimeSync';
import NoSyncStrategy from '../NoSyncStrategy/NoSyncStrategy';

export default class SyncStrategy extends NoSyncStrategy {
  constructor() {
    super();
    this.prevPlayerTime = 0;
    this.prevElapsedTime = 0;
    this.nextOffsetForPlayerStart = 0;
    this.framesSyncronized = 0;
    this.isSeekingTimeoutActive = false;
  }

  init(player) {
    if (this.player) {
      this.destroy();
    }
    this.player = player;
    this.timeupdateHandler = this.onTimeUpdate.bind(this);
    this.seekedHandler = this.onPlayerSeeked.bind(this);
    this.player.on(Events.TIME_UPDATE, this.timeupdateHandler);
    this.player.on(Events.SEEKED, this.seekedHandler);
  }

  getTimeToSyncWith() {
    return this.player.getCurrentTime();
  }

  onPlayerSeeked() {
    const currentTime = this.player.getCurrentTime();
    if (!this.isPlayerAhead(currentTime)) {
      this.handleTimeUpdate(true);
    } else if (!this.isSeekingTimeoutActive) {
      this.player.pause();
      this.framesSyncronized = 0;
      this.setSeekingTimeout(currentTime);
    }
  }

  onTimeUpdate() {
    if (this.player.isPaused()) return;
    const playerTime = this.player.getCurrentTime();

    if (!this.isPlayerAhead(playerTime)) {
      this.calculateNextOffsetForPlayerStart(playerTime);
    }

    this.handleTimeUpdate();
  }

  calculateNextOffsetForPlayerStart(playerTime) {
    const elapsedTime = this.getTimeToSyncWith();
    const playerTimeDiff = playerTime - this.prevPlayerTime;
    const elapsedTimeDiff = elapsedTime - this.prevElapsedTime;
    const timeSyncDelay = Math.abs(playerTimeDiff - elapsedTimeDiff);
    this.prevPlayerTime = playerTime;
    this.prevElapsedTime = elapsedTime;
    this.setNextOffsetForPlayerStart(timeSyncDelay);
  }

  handleTimeUpdate(startPlaying = false) {
    const elapsedSeconds = this.getTimeToSyncWith();

    const timeDiff = Math.abs(elapsedSeconds - this.player.getCurrentTime());

    if (timeDiff > MAX_ELAPSED_OFFSET) {
      this.player.log('THE PLAYER IS OUT OF SYNC, timeDiff', timeDiff);
      this.framesSyncronized = 0;
      this.updateCurrentTime(elapsedSeconds);
    } else {
      if (startPlaying) {
        this.player.playIfAllowed();
      }
      this.framesSyncronized += 1;

      if (this.framesSyncronized === SYNC_ITERATIONS) {
        this.player.trigger(Events.CAUGHT_UP);
      }
    }
  }

  updateCurrentTime(value) {
    const currentTime = this.player.getCurrentTime();
    const timeDiff = value - currentTime;
    const isFuture = timeDiff > 0;

    if (!isFuture) {
      this.player.log('PLAYER IS NOW AHEAD!');
      const maxAllowedTimeDiff = (PUSH_FORWARD_MS + this.nextOffsetForPlayerStart) / 1000;

      if (Math.abs(timeDiff) > maxAllowedTimeDiff) {
        this.player.setCurrentTime(this.getTimeToSyncWith());
        return;
      }

      // we are ahead, do nothing, just wait while the elapsed time will be the same
      if (this.isSeekingTimeoutActive) {
        return;
      }
      this.player.pause();

      this.player.log('START WAITING FOR SYNCHRONIZED TIME');
      this.setSeekingTimeout(currentTime);
      return;
    }

    this.pushForwardAndSynchronize(value);
  }

  pushForwardAndSynchronize(value) {
    const elapsedSeekingTime = PUSH_FORWARD_MS + this.nextOffsetForPlayerStart;
    const currentTimeDiff = value + elapsedSeekingTime / 1000;

    this.player.setCurrentTime(currentTimeDiff);
  }

  setSeekingTimeout(playerTime) {
    this.isSeekingTimeoutActive = true;
    requestAnimFrame(() => {
      if (this.getTimeToSyncWith() + this.nextOffsetForPlayerStart < playerTime) {
        this.setSeekingTimeout(playerTime);
      } else {
        this.player.log('CAUGHT UP WITH SYNCHRONIZED TIME -> playerTime', playerTime);
        this.isSeekingTimeoutActive = false;
        this.player.playIfAllowed();
      }
    });
  }

  isPlayerAhead(playerTime) {
    return playerTime > this.getTimeToSyncWith();
  }

  setNextOffsetForPlayerStart(value) {
    this.nextOffsetForPlayerStart = value;
  }

  destroy() {
    this.player.off(Events.TIME_UPDATE, this.timeupdateHandler);
    this.player.off(Events.SEEKED, this.seekedHandler);
  }
}
