import React, { useEffect } from 'react';
import './App.scss';
import { HashRouter } from 'react-router-dom';
import Router from './pages/Router';
import LoginForm from './components/LoginForm';
import apiConfig from './config/api-config';

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(null);

  useEffect(() => {
    fetch(`${apiConfig.baseUrl}/token-verify`, { credentials: 'include' }).then(
      (response) => {
        setIsLoggedIn(response.status === 200);
      },
    );
  }, []);

  const renderAppChildren = () => {
    if (isLoggedIn !== null) {
      return isLoggedIn ? (
        <HashRouter>
          <Router />
        </HashRouter>
      ) : (
        <LoginForm setIsLoggedIn={setIsLoggedIn} />
      );
    }
    return null;
  };

  return <div className="App">{renderAppChildren()}</div>;
}

export default App;
