import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import './CastMemberCard.scss';
import FullscreenPopup from '../FullscreenPopup';

function CastMemberCard(props) {
  const { details: { cardImageUrl, detailsImageUrl, title } } = props;
  const [isDetailsImageShown, setIsDetailsImageShown] = useState(false);

  const toggleCastMemberDetails = () => {
    setIsDetailsImageShown((prevState) => !prevState);
  };

  return (
    <>
      <div
        className="cast-member-card"
        onClick={toggleCastMemberDetails}
        role="presentation"
      >
        <img src={cardImageUrl} alt={title} />
      </div>
      {isDetailsImageShown && (
        <FullscreenPopup onClose={toggleCastMemberDetails}>
          <img src={detailsImageUrl} alt={title} />
        </FullscreenPopup>
      )}
    </>
  );
}

export default CastMemberCard;

CastMemberCard.propTypes = {
  details: shape({
    cardImageUrl: string,
    detailsImageUrl: string,
    title: string,
  }).isRequired,
};
