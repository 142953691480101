import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EvidenceButton from './EvidenceButton';
import Episode from '../../../../api/services/Episode';
import EvidencePopup from './EvidencePopup';

export function Evidence() {
  const evidenceAvailableAt = useSelector((state) => state.evidence.availableAt);
  const currentTime = useSelector((state) => state.player.playedTime);

  const [isEvidenceEnabled, setIsEvidenceEnabled] = useState(false);
  const [isEvidencePopupOpened, setIsEvidencePopupOpened] = useState(false);
  const [evidenceImages, setEvidenceImages] = useState(false);

  useEffect(() => {
    if (evidenceAvailableAt === null) return;
    const isEnabled = evidenceAvailableAt < currentTime;
    setIsEvidenceEnabled(isEnabled);
  }, [evidenceAvailableAt, currentTime]);

  useEffect(() => {
    if (isEvidenceEnabled) {
      Episode.getEvidence().then(({ images }) => {
        setEvidenceImages(images);
      });
    }
  }, [isEvidenceEnabled]);

  const openEvidence = () => {
    setIsEvidencePopupOpened(true);
  };

  const closeEvidence = () => {
    setIsEvidencePopupOpened(false);
  };

  return (
    <div>
      <EvidenceButton onClick={openEvidence} isEnabled={isEvidenceEnabled && !!evidenceImages} />
      {isEvidencePopupOpened && <EvidencePopup images={evidenceImages} onClose={closeEvidence} />}
    </div>
  );
}

export default Evidence;
