import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { secondsToHms } from '../../../../utils/utils';
import { getElapsed, requestAnimFrame } from '../../services/TimeSync';
import { togglePlay } from '../../../../reducers/player';

let isProgress = true;

function LiveModeControls({ startTime, playedSeconds }) {
  const [elapsedTime, setElapsedTime] = useState('0');
  const [elapsedTimeS, setElapsedTimeS] = useState(0);

  const dispatch = useDispatch();
  const isPlaying = useSelector((state) => state.player.isPlaying);
  const isVideoEnded = useSelector((state) => state.player.isVideoEnded);

  useEffect(() => {
    setElapsedTime(startTime);
    setElapsed();
  }, [startTime]);

  useEffect(() => {
    if (isVideoEnded) stopElapsedTimer();
  }, [isVideoEnded]);

  function setElapsed() {
    const elapsedMS = getElapsed();
    setElapsedTimeS(elapsedMS / 1000);
    setElapsedTime(`${(elapsedMS / 1000).toFixed(3)}`);

    if (isProgress) requestAnimFrame(setElapsed);
  }

  function stopElapsedTimer() {
    isProgress = false;
  }

  function onTogglePlayClick() {
    dispatch(togglePlay());
  }

  return (
    <div>
      <hr />
      <div>
        <table>
          <tbody>
            <tr>
              <td>Played Time:</td>
              <td>{secondsToHms(playedSeconds)}</td>
            </tr>
            <tr>
              <td>Played Timestamp:</td>
              <td>{playedSeconds}</td>
            </tr>
            <tr>
              <td>Elapsed time:</td>
              <td>{elapsedTime}</td>
            </tr>
            <tr>
              <td>Diff with elapsed:</td>
              <td>{Math.abs(playedSeconds - elapsedTimeS).toFixed(5)}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="d-flex">
          <button type="button" className="btn btn-danger btn-sm" onClick={onTogglePlayClick}>{isPlaying ? 'Pause' : 'Play'}</button>
          &nbsp;
          <button type="button" className="btn btn-danger btn-sm" onClick={stopElapsedTimer}>Stop elapsed timer</button>
        </div>
      </div>

    </div>
  );
}

export default LiveModeControls;

LiveModeControls.propTypes = {
  startTime: PropTypes.number,
  playedSeconds: PropTypes.number,
};

LiveModeControls.defaultProps = {
  startTime: 0,
  playedSeconds: 0,
};
