import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EpisodeTimer from '../EpisodeTimer';
import useEpisodeData from '../../hooks/useEpisodeData';
import CastMemberCard from '../CastMemberCard';
import './EpisodeInfo.scss';
import { getFormattedEpisodeStartTime } from '../../utils/utils';
import Trailer from '../Trailer';

function EpisodeInfo({ onTimerFinishAction, episodeId }) {
  const { episodeInfo, episodeStartTime, episodeEndTime } = useEpisodeData(episodeId);
  const [isTrailerFullScreen, setIsTrailerFullscreen] = useState(false);

  const toggleTrailer = () => {
    setIsTrailerFullscreen((prevState) => !prevState);
  };

  return (
    <div className="episode-info">
      {episodeInfo && (
        <div>
          {episodeInfo.trailerUrl && (
            <Trailer
              src={episodeInfo.trailerUrl}
              isFullScreen={isTrailerFullScreen}
              onPopupClose={toggleTrailer}
            />
          )}
          <h1>{episodeInfo.name}</h1>
          <button type="button" onClick={toggleTrailer}>Watch trailer</button>
          <p>
            Premiere on:
            <br />
            {getFormattedEpisodeStartTime(episodeStartTime)}
          </p>
          {episodeStartTime && episodeEndTime && (
            <EpisodeTimer
              episodeStartTime={episodeStartTime}
              episodeEndTime={episodeEndTime}
              onTimerFinishAction={onTimerFinishAction}
            />
          )}
          <div className="cast-members">
            {episodeInfo.cast && episodeInfo.cast.map((castMember) => (
              <CastMemberCard key={castMember.cardImageUrl} details={castMember} />
            ))}
          </div>
          <p>{episodeInfo.description}</p>
        </div>
      )}
      <br />
    </div>
  );
}
export default EpisodeInfo;

EpisodeInfo.propTypes = {
  onTimerFinishAction: PropTypes.func,
  episodeId: PropTypes.string,
};

EpisodeInfo.defaultProps = {
  onTimerFinishAction: () => null,
  episodeId: '',
};
