import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import TicketValidationForm from '../../components/TicketValidationForm';
import EpisodeInfo from '../../components/EpisodeInfo';
import { setPlayerMode, showEpisode } from '../../reducers/player';
import useLocalStorageData from '../../hooks/useLocalStorageData';
import { SEASON_ID } from '../../constants';
import './Episode.scss';

function Episode() {
  const { episodeId } = useParams();
  const EPISODE_ID = SEASON_ID + episodeId;
  const isEpisodeShown = useSelector((state) => state.player.isEpisodeShown);
  const [isTimeToShow, setIsTimeToShow] = useState(false);
  const dispatch = useDispatch();
  const isEpisodeActivated = useLocalStorageData(EPISODE_ID, SEASON_ID);

  useEffect(() => {
    if (isEpisodeActivated) {
      dispatch(showEpisode());
    }

    const params = new URL(document.location).searchParams;
    dispatch(setPlayerMode(params.get('mode')));
  }, []);

  const onActivate = () => {
    dispatch(showEpisode());
  };
  return (
    <div>
      <EpisodeInfo onTimerFinishAction={setIsTimeToShow} episodeId={EPISODE_ID} />
      {!isEpisodeShown && (
        <TicketValidationForm
          onActivate={onActivate}
          episodeId={EPISODE_ID}
          seasonId={SEASON_ID}
        />
      )}
      {isEpisodeShown && isTimeToShow && (
        <Link to={`/episode/${episodeId}/watch`}>
          Episode
          {' '}
          {EPISODE_ID}
          {' '}
          player
        </Link>
      )}
    </div>
  );
}

export default Episode;
