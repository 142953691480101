import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Episode from '../../../api/services/Episode';
import videoPlayerConfig from '../config/video-player-config';
import {
  hideEpisode,
  setCamerasPosition,
  setMainCameraOptions,
  setRoomsConfig, setSecondaryCameraOptions,
} from '../../../reducers/player';
import disabledControlBarOptions from '../config/video-player-disabled-controls-config';
import { startPolling } from '../../../reducers/polling';
import { setEvidenceAvailabilityTime } from '../../../reducers/evidence';

export function useEpisodeVideoData(isLiveMode) {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const { rooms, evidence } = await Episode.getEpisodeVideoData();

      const camerasPosition = {
        main: createCameraStateInitialObject(rooms[0]),
        secondary: rooms.slice(1).map((room) => createCameraStateInitialObject(room)),
      };

      const params = (new URL(document.location)).searchParams;
      const mainVideoHeight = params.get('videoHeight') || 720;

      dispatch(setRoomsConfig(rooms));

      dispatch(setMainCameraOptions({
        ...videoPlayerConfig,
        height: mainVideoHeight,
        controls: !isLiveMode,
        controlBar: isLiveMode ? disabledControlBarOptions : videoPlayerConfig.controlBar,
      }));
      dispatch(setSecondaryCameraOptions({
        ...videoPlayerConfig,
        muted: true,
        controls: false,
        controlBar: disabledControlBarOptions,
      }));

      dispatch(setCamerasPosition(camerasPosition));
      dispatch(setEvidenceAvailabilityTime(evidence.availableAt));

      dispatch(startPolling());
    }
    fetchData().catch(() => dispatch(hideEpisode()));
  }, [isLiveMode]);

  function createCameraStateInitialObject(room) {
    return {
      roomId: room.roomId,
      activeCameraId: room.cameras[0].cameraId,
      isReady: false,
    };
  }
}
