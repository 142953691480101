export default function useLocalStorageData(episodeId, seasonId) {
  const validatedEpisodes = localStorage.getItem('episodes');
  const validatedSeasons = localStorage.getItem('seasons');

  const episodes = validatedEpisodes ? JSON.parse(validatedEpisodes) : '';
  const seasons = validatedSeasons ? JSON.parse(validatedSeasons) : '';

  const isEpisodeActivated = !!(
    (episodes && episodes[episodeId]) || (seasons && seasons[seasonId])
  );

  return isEpisodeActivated;
}
