import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './EpisodeCard.scss';

function EpisodeCard({ info }) {
  const { coverUrl, episodeNumber, startTime } = info;

  const infoList = useMemo(
    () => (
      <>
        <li key={`info-${episodeNumber}`}>
          <strong>Case</strong>
          {' '}
          {episodeNumber}
        </li>
        <li key={`info-${startTime}`}>
          <strong>Premier on</strong>
          {' '}
          {startTime}
        </li>
      </>
    ),
    [],
  );

  return (
    <div className="episode-card">
      {coverUrl ? (
        <img src={coverUrl} alt="Episode cover" width={250} height={250} />
      ) : null}
      <div className="episode-card-description">
        <h3>Episode information</h3>
        <ul>{infoList}</ul>
        <Link to={`/episode/e${episodeNumber}`}>
          Solve
        </Link>
      </div>
    </div>
  );
}
export default EpisodeCard;

EpisodeCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.object,
};

EpisodeCard.defaultProps = {
  info: {},
};
