import SyncStrategy from '../SyncStrategy/SyncStrategy';

export default class SyncWithPlayerStrategy extends SyncStrategy {
  constructor(playerToSyncWith) {
    super();
    this.playerToSyncWith = playerToSyncWith;
  }

  getTimeToSyncWith() {
    return this.playerToSyncWith.getCurrentTime();
  }
}
