import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getServerTime } from '../../api/services/Timer';
import { formTimerOutput, parseToMs } from './utils';
import { setPlayerMode } from '../../reducers/player';

function EpisodeTimer({
  onTimerFinishAction,
  episodeStartTime,
  episodeEndTime,
}) {
  const [timerStartTime, setTimerStartTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isFinished, setIsFinished] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getServerTime()
      .then(({ currentTime }) => {
        setTimerStartTime(currentTime);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (timerStartTime && episodeStartTime) {
      setTimeLeft(parseToMs(episodeStartTime) - parseToMs(timerStartTime));

      const interval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 0) {
            clearInterval(interval);
            setIsFinished(true);
            return 0;
          }
          return prevTimeLeft - 1000;
        });
      }, 1000);
    }
  }, [timerStartTime, episodeStartTime]);

  useEffect(() => {
    if (isFinished) {
      getServerTime()
        .then(({ currentTime }) => {
          if (parseToMs(currentTime) >= parseToMs(episodeEndTime)) {
            dispatch(setPlayerMode('ondemand'));
            onTimerFinishAction(true);
          } else if (parseToMs(currentTime) >= parseToMs(episodeStartTime)) {
            dispatch(setPlayerMode('live'));
            onTimerFinishAction(true);
          } else {
            dispatch(setPlayerMode(''));
            setTimerStartTime(currentTime);
            setIsFinished(false);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err.message);
        });
    }
  }, [isFinished]);

  const formattedTime = timeLeft && formTimerOutput(timeLeft);
  return formattedTime ? (
    <div>
      <p>Episode starts in:</p>
      {' '}
      <span>{formattedTime}</span>
    </div>
  ) : null;
}

export default EpisodeTimer;

EpisodeTimer.propTypes = {
  episodeStartTime: PropTypes.string,
  episodeEndTime: PropTypes.string,
  onTimerFinishAction: PropTypes.func,
};

EpisodeTimer.defaultProps = {
  episodeStartTime: '',
  episodeEndTime: '',
  onTimerFinishAction: () => null,
};
