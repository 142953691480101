import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Episode from '../../api/services/Episode';
import EpisodeCard from '../../components/EpisodeCard';

import './Home.scss';

function Home() {
  const [episodesInfo, setEpisodesInfo] = useState([]);
  useEffect(() => {
    Episode.getEpisodesPublicData().then((info) => {
      setEpisodesInfo(info);
    });
  }, []);

  return (
    <div>
      <div className="links-container">
        <Link to="/about" relative="path">
          About
        </Link>
        <Link to="/faq" relative="path">
          FAQ
        </Link>
      </div>
      {episodesInfo.length
        ? episodesInfo.map((episodeInfo) => (
          <EpisodeCard
            info={episodeInfo}
            key={`episode-card-${episodeInfo.id}`}
          />
        ))
        : null}
    </div>
  );
}

export default Home;
