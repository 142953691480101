import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import { func, string } from 'prop-types';
import CustomPlayerVideoJS from '../../../../wrappers/CustomPlayerVideoJS';
import SyncWithElapsedTimeStrategy from '../../../../strategies/SyncWithElapsedTimeStrategy';
import SyncWithPlayerStrategy from '../../../../strategies/SyncWithPlayerStrategy';
import Events from '../../../../constants/events';
import cameraOptions from '../../../../types/cameraOptions';

export function MainCameraLive(props) {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);
  const oldPlayerRef = useRef(null);
  const customPlayerRef = useRef(null);
  const oldCustomPlayerRef = useRef(null);
  const { options, onReady, roomId } = props;

  const [isBlurred] = useState(false);

  const onPlayerReady = (player) => {
    if (player) {
      const customPlayer = new CustomPlayerVideoJS(player, options.cameraId, true);

      if (oldPlayerRef.current && oldCustomPlayerRef.current.isPaused()) {
        initNewPlayerInsteadOfCurrent(customPlayer);
      } else if (oldPlayerRef.current) {
        initNewPlayerAndSyncWithCurrent(customPlayer);
      } else {
        customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());
        customPlayer.unmute();
      }

      onReady(customPlayer);

      customPlayer.hideControls();

      customPlayerRef.current = customPlayer;
    }
  };

  useEffect(() => {
    if (customPlayerRef.current) {
      customPlayerRef.current.mute();
    }
  }, [roomId]);

  useEffect(() => {
    if (!playerRef.current) {
      createPlayer();
    } else if (playerRef.current && !customPlayerRef.current) {
      // the player started initializing but is not ready yet
      playerRef.current.dispose();
      createPlayer();
    } else {
      destroyOldPlayer();
      assignOldPlayer();
      createPlayer();
    }
  }, [options]);

  function assignOldPlayer() {
    oldPlayerRef.current = playerRef.current;
    oldCustomPlayerRef.current = customPlayerRef.current;
    customPlayerRef.current = null;
    oldCustomPlayerRef.current.playerId += '_old';

    document.getElementById(oldPlayerRef.current.id()).style.display = 'none';
  }

  function destroyOldPlayer() {
    const oldPlayer = oldPlayerRef.current;
    if (oldPlayer && !oldPlayer.isDisposed()) {
      oldPlayer.dispose();
      oldPlayerRef.current = null;
    }
  }

  function createPlayer() {
    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(
      document.createElement('video-js'),
    );

    // eslint-disable-next-line no-multi-assign
    const player = (playerRef.current = videojs(videoElement, options, () => {
      onPlayerReady(player);
    }));
  }

  function initNewPlayerInsteadOfCurrent(customPlayer) {
    const seekedHandler = () => {
      customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());
      customPlayer.off(Events.SEEKED, seekedHandler);
    };
    customPlayer.on(Events.SEEKED, seekedHandler);
    customPlayer.setCurrentTime(oldCustomPlayerRef.current.getCurrentTime());
    destroyOldPlayer();
    customPlayer.unmute();
  }

  function initNewPlayerAndSyncWithCurrent(customPlayer) {
    const caughtUpHandler = () => {
      customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());
      customPlayer.unmute();
      destroyOldPlayer();
      customPlayer.off(Events.CAUGHT_UP, caughtUpHandler);
    };
    customPlayer.on(Events.CAUGHT_UP, caughtUpHandler);
    customPlayer.setSyncStrategy(new SyncWithPlayerStrategy(oldCustomPlayerRef.current));
  }

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="camera">
      <div ref={placeholderRef} />
      {isBlurred && <div className="blur-overlay" />}
    </div>
  );
}

export default MainCameraLive;

MainCameraLive.propTypes = {
  options: cameraOptions.isRequired,
  onReady: func.isRequired,
  roomId: string.isRequired,
};
