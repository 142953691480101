import React, { useEffect } from 'react';
import './EpisodePlayer.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useEpisodeVideoData } from './hooks/useEpisodeVideoData';
import EpisodePlayerOnDemand from './components/EpisodePlayerOnDemand';
import EpisodePlayerLive from './components/EpisodePlayerLive';
import { usePolling } from './hooks/usePolling';
import { useTabDetection } from './hooks/useTabDetection';
import { setOpenedTabId } from '../../utils/tab-utils';
import Evidence from './components/Evidence';

const isPlayerConfigEqual = (oldValue, newValue) => {
  const isEqual = oldValue.main.roomId === newValue.main.roomId
    && oldValue.secondary.every((camera, index) => (
      camera.roomId === newValue.secondary[index].roomId
    ));
  return isEqual;
};

function EpisodePlayer({ episodeId, seasonId }) {
  const isLiveMode = useSelector((state) => state.player.isLive);
  useEpisodeVideoData(isLiveMode);

  const { isMultipleWatching } = usePolling(episodeId, seasonId);
  const { anotherTabDetected } = useTabDetection();

  const {
    main: mainConfig,
    secondary: secondaryConfigs,
  } = useSelector((state) => state.player.camerasPosition, isPlayerConfigEqual);

  useEffect(() => {
    setOpenedTabId();
  }, []);

  if (isMultipleWatching || anotherTabDetected) {
    return (
      <div>
        The video cannot be displayed. Multiple connections per ticket are
        detected.
      </div>
    );
  }

  return (
    <div>
      <Evidence />
      {isLiveMode ? (
        <EpisodePlayerLive
          mainConfig={mainConfig}
          secondaryConfigs={secondaryConfigs}
        />
      ) : (
        <EpisodePlayerOnDemand
          mainConfig={mainConfig}
          secondaryConfigs={secondaryConfigs}
        />
      )}
    </div>
  );
}

export default EpisodePlayer;

EpisodePlayer.propTypes = {
  episodeId: PropTypes.string,
  seasonId: PropTypes.string,
};

EpisodePlayer.defaultProps = {
  episodeId: null,
  seasonId: null,
};
