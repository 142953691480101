import React, { useEffect, useMemo, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Camera from '../Camera';
import { setActiveCameraId, switchToMainCamera } from '../../../../reducers/player';

export function Room(props) {
  const { roomId, onReady, isMain } = props;

  const [isCameraSwitchInProgress, setCameraSwitchInProgress] = useState(null);

  const activeCameraId = useSelector((state) => {
    const { camerasPosition: { main, secondary } } = state.player;
    if (main.roomId === roomId) {
      return main.activeCameraId;
    }

    const secondaryCameraIndex = secondary
      .findIndex((camera) => camera.roomId === roomId);

    return secondary[secondaryCameraIndex].activeCameraId;
  });

  const { cameras } = useSelector((state) => {
    const config = state.player.roomsConfig.find((room) => room.roomId === roomId);

    return config ?? {
      cameras: [],
    };
  });

  const dispatch = useDispatch();

  const onCameraReady = (player) => {
    setCameraSwitchInProgress(false);
    onReady(player);
  };

  const memoizedCamera = useMemo(() => (
    <Camera
      onReady={onCameraReady}
      activeCameraId={activeCameraId}
      isMain={isMain}
      roomId={roomId}
      cameras={cameras}
    />
  ), [isMain, roomId, activeCameraId]);

  const switchCamera = (cameraId) => {
    dispatch(setActiveCameraId({
      roomId,
      activeCameraId: cameraId,
    }));
  };

  const setMainCamera = () => {
    dispatch(switchToMainCamera({ roomId }));
  };

  useEffect(() => {
    setCameraSwitchInProgress(true);
  }, [activeCameraId]);

  return (
    <div className="video-room">
      <div>
        {roomId}
      </div>
      {activeCameraId && memoizedCamera}
      <div className="room-buttons">
        {cameras.map((camera, cameraIndex) => (
          <button
            type="button"
            key={camera.cameraId}
            onClick={() => switchCamera(camera.cameraId)}
            disabled={(!!isMain
              && !!isCameraSwitchInProgress)
              || camera.cameraId === activeCameraId}
          >
            Camera
            {cameraIndex + 1}
          </button>
        ))}
        {!isMain && <button type="button" onClick={setMainCamera}>Switch to Main</button>}
      </div>
    </div>
  );
}

export default Room;

Room.propTypes = {
  onReady: func.isRequired,
  isMain: bool,
  roomId: string.isRequired,
};

Room.defaultProps = {
  isMain: false,
};
