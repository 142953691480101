import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomPlayerVideoJS from '../../../wrappers/CustomPlayerVideoJS';
import '../Camera.scss';
import SyncWithElapsedTimeStrategy from '../../../strategies/SyncWithElapsedTimeStrategy';
import cameraOptions from '../../../types/cameraOptions';

export function SecondaryCamera(props) {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);
  const customPlayerRef = useRef(null);
  const { options, onReady } = props;

  const isPlaying = useSelector((state) => state.player.isPlaying);
  const isLiveMode = useSelector((state) => state.player.isLive);

  const [isBlurred] = useState(false);

  useEffect(() => {
    if (!playerRef.current) {
      createPlayer();
    } else if (playerRef.current && !customPlayerRef.current) {
      // the player started initializing but is not ready yet
      playerRef.current.dispose();
      createPlayer();
    } else {
      updatePlayer();
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  function createPlayer() {
    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(
      document.createElement('video-js'),
    );

    // eslint-disable-next-line no-multi-assign
    const player = (playerRef.current = videojs(videoElement, options, () => {
      onPlayerReady(player);
    }));
  }

  function onPlayerReady(player) {
    if (player) {
      createCustomPlayer(player);
    }
  }

  function createCustomPlayer(player) {
    const customPlayer = new CustomPlayerVideoJS(player, options.cameraId);
    if (isLiveMode) {
      customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());
      customPlayer.hideControls();
    }
    onReady(customPlayer);

    customPlayerRef.current = customPlayer;
  }

  function updatePlayer() {
    const player = playerRef.current;
    player.src(options.sources);
    customPlayerRef.current.playerId = options.cameraId;
    if (isPlaying) player.play();
  }

  return (
    <div className="camera">
      <div ref={placeholderRef} />
      {isBlurred && <div className="blur-overlay" />}
    </div>
  );
}

export default SecondaryCamera;

SecondaryCamera.propTypes = {
  options: cameraOptions.isRequired,
  onReady: PropTypes.func.isRequired,
};
