import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EpisodeTimer from '../../components/EpisodeTimer';
import EpisodePlayer from '../../components/EpisodePlayer';
import useEpisodeData from '../../hooks/useEpisodeData';
import useLocalStorageData from '../../hooks/useLocalStorageData';
import { SEASON_ID } from '../../constants';
import './EpisodeWatch.scss';

function EpisodeWatch() {
  const navigate = useNavigate();
  const { episodeId } = useParams();
  const EPISODE_ID = SEASON_ID + episodeId;
  const [isEpisodeShown, setIsEpisodeShown] = useState(false);
  const [isTimeToShow, setIsTimeToShow] = useState(false);
  const { episodeStartTime, episodeEndTime } = useEpisodeData(EPISODE_ID);
  const isEpisodeActivated = useLocalStorageData(EPISODE_ID, SEASON_ID);

  useEffect(() => {
    if (isEpisodeActivated) {
      setIsEpisodeShown(true);
    } else {
      navigate(`/episode/${episodeId}`);
    }
  }, []);

  return (
    <>
      {!isTimeToShow && episodeStartTime && episodeEndTime && (
        <EpisodeTimer
          episodeStartTime={episodeStartTime}
          episodeEndTime={episodeEndTime}
          onTimerFinishAction={setIsTimeToShow}
        />
      )}
      <br />
      {isEpisodeShown && isTimeToShow && (
        <EpisodePlayer episodeId={EPISODE_ID} seasonId={SEASON_ID} />
      )}
    </>
  );
}

export default EpisodeWatch;
