import SyncWithPlayerStrategy from '../SyncWithPlayerStrategy/SyncWithPlayerStrategy';
import Events from '../../constants/events';
import { PUSH_FORWARD_MS } from '../../config/video-sync-config';

export default class SyncWithPlayerOnDemandStrategy extends SyncWithPlayerStrategy {
  init(player) {
    super.init(player);

    this.playHandler = this.onPlay.bind(this);
    this.pauseHandler = this.onPause.bind(this);

    this.playerToSyncWith.on(Events.PLAY, this.playHandler);
    this.playerToSyncWith.on(Events.PAUSE, this.pauseHandler);

    this.player.setCurrentTime(this.playerToSyncWith.getCurrentTime());
    this.player.setIsPlaying(!this.playerToSyncWith.isPaused());
  }

  onPlay() {
    this.player.setIsPlaying(true);
  }

  onPause() {
    this.player.setIsPlaying(false);
  }

  onTimeUpdate() {
    if (this.playerToSyncWith.isDisposed()) return;
    if (this.player.isPaused() && this.playerToSyncWith.isPaused()) return;
    const playerTime = this.player.getCurrentTime();

    if (!this.isPlayerAhead(playerTime)) {
      this.calculateNextOffsetForPlayerStart(playerTime);
    }

    this.handleTimeUpdate();
  }

  updateCurrentTime(value) {
    const currentTime = this.player.getCurrentTime();
    const timeDiff = value - currentTime;
    const isFuture = timeDiff > 0;

    if (!isFuture) {
      this.player.log('PLAYER IS NOW AHEAD!');
      // we are ahead, do nothing, just wait while the elapsed time will be the same
      if (this.isSeekingTimeoutActive) {
        return;
      }

      const maxAllowedTimeDiff = (PUSH_FORWARD_MS + this.nextOffsetForPlayerStart) / 1000;

      if (Math.abs(timeDiff) > maxAllowedTimeDiff) {
        this.player.log('REWIND BACKWARD TO', value);
        this.pushForwardAndSynchronize(value);
        return;
      }

      this.player.pause();

      this.player.log('START WAITING FOR SYNCHRONIZED TIME');
      this.setSeekingTimeout(currentTime);
      return;
    }

    this.pushForwardAndSynchronize(value);
  }

  destroy() {
    super.destroy();
    this.playerToSyncWith.off(Events.PLAY, this.playHandler);
    this.playerToSyncWith.off(Events.PAUSE, this.pauseHandler);
  }

  getTimeToSyncWith() {
    return this.playerToSyncWith.getCurrentTime();
  }
}
