import apiConfig from '../../config/api-config';

async function getEpisodeVideoData() {
  return fetch(`${apiConfig.baseUrl}/episode/links`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      seasonId: 's1',
      episodeId: 's1e1',
    }),
    credentials: 'include',
  }).then((data) => data.json());
}

function getEpisodePublicData(episodeId) {
  return fetch(`${apiConfig.baseUrl}/episode/info`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      seasonId: 's1',
      episodeId,
    }),
  }).then((data) => data.json());
}

function getEpisodesPublicData() {
  const queryParams = new URLSearchParams({
    seasonId: encodeURIComponent('s1'),
  });
  return fetch(`${apiConfig.baseUrl}/episode/all?${queryParams}`, {
    credentials: 'include',
  }).then((data) => data.json());
}

async function getEvidence() {
  return fetch(`${apiConfig.baseUrl}/episode/evidence`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      seasonId: 's1',
      episodeId: 's1e1',
    }),
    credentials: 'include',
  }).then((data) => data.json());
}

export default {
  getEpisodeVideoData,
  getEpisodePublicData,
  getEpisodesPublicData,
  getEvidence,
};
