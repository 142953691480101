import React from 'react';
import './About.scss';

function About() {
  return (
    <div>
      <h1>About</h1>
    </div>
  );
}

export default About;
