import React from 'react';
import './FullscreenPopup.scss';
import { arrayOf, func, oneOfType, node } from 'prop-types';

export function FullscreenPopup(props) {
  const { onClose, children } = props;
  return (
    <div className="fullscreen-popup">
      <button type="button" onClick={onClose}>Close</button>
      <div>{children}</div>
    </div>
  );
}

export default FullscreenPopup;

FullscreenPopup.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
  onClose: func,
};

FullscreenPopup.defaultProps = {
  children: [],
  onClose: () => {},
};
