import apiConfig from '../../config/api-config';

async function validate(activationCode, episodeId) {
  const queryParams = new URLSearchParams({
    activationCode: encodeURIComponent(activationCode),
    episodeId: encodeURIComponent(episodeId),
  });
  const url = `${apiConfig.baseUrl}/ticket/validate?${queryParams}`;
  const response = await fetch(url, { credentials: 'include' });
  return response.json();
}

function updateValidatedEpisodesData(validationInfo) {
  const validatedItems = localStorage.getItem(
    validationInfo.isSeasonal ? 'seasons' : 'episodes',
  );
  const data = validatedItems ? JSON.parse(validatedItems) : {};

  data[
    validationInfo.isSeasonal
      ? validationInfo.seasonId
      : validationInfo.episodeId
  ] = validationInfo.endShowDate;

  localStorage.setItem(
    validationInfo.isSeasonal ? 'seasons' : 'episodes',
    JSON.stringify(data),
  );
}

async function poll(episodeId, seasonId) {
  const queryParams = new URLSearchParams({
    episodeId: encodeURIComponent(episodeId),
    seasonId: encodeURIComponent(seasonId),
  });

  const url = `${apiConfig.baseUrl}/ticket/poll?${queryParams}`;
  const response = await fetch(url, { credentials: 'include' });
  return response.json();
}

export default {
  validate,
  updateValidatedEpisodesData,
  poll,
};
