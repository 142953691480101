import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import HlsVideoPlayer from '../HlsVideoPlayer';
import './Trailer.scss';
import controlBarConfig from '../../config/control-bar-config';

function Trailer({ src, isFullScreen, onPopupClose }) {
  const [trailerOptions, setTrailerOptions] = useState(null);

  useEffect(() => {
    if (!src) return;
    setTrailerOptions({
      enableSourceset: true,
      sources: [{
        src,
        type: 'application/x-mpegURL',
      }],
      autoplay: true,
      muted: !isFullScreen,
      controls: isFullScreen,
      loop: true,
      controlBar: controlBarConfig,
      bigPlayButton: false,
      loadingSpinner: false,
    });
  }, [src, isFullScreen]);
  return trailerOptions ? (
    <div className="trailer-placeholder" data-testid="trailer">
      <div className={isFullScreen ? 'trailer trailer-fullscreen' : 'trailer'}>
        <button type="button" onClick={onPopupClose}>X</button>
        <HlsVideoPlayer options={trailerOptions} />
      </div>
    </div>
  ) : null;
}

export default Trailer;

Trailer.propTypes = {
  src: string.isRequired,
  isFullScreen: bool,
  onPopupClose: func.isRequired,
};

Trailer.defaultProps = {
  isFullScreen: false,
};
