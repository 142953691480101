import React from 'react';
import './FAQ.scss';

function FAQ() {
  return (
    <div>
      <h1>FAQ</h1>
    </div>
  );
}

export default FAQ;
