import { isIOS } from 'react-device-detect';
import Events from '../constants/events';
import NoSyncStrategy from '../strategies/NoSyncStrategy';

export default class CustomPlayer {
  constructor(player, playerId, enableLogging = false) {
    this.log('CREATE CUSTOM PLAYER', playerId);
    this.playerId = playerId;
    this.originalPlayer = player;
    this.isSeeking = false;
    this.enableLogging = enableLogging;
    this.setSyncStrategy(new NoSyncStrategy());

    this.mute();

    this.on(Events.SEEKING, () => {
      this.log('on SEEKING');
      this.isSeeking = true;
    });

    this.on(Events.SEEKED, () => {
      this.log('on SEEKED');
      this.isSeeking = false;
    });

    this.on('ended', () => {
      this.log('on ENDED');
    });

    this.on('error', (event) => {
      this.log('on ERROR', event);
    });

    this.on('suspend', (event) => {
      this.log('on SUSPEND', event);
    });

    this.on('abort', (event) => {
      this.log('on ABORT', event);
    });

    this.on('stalled', (event) => {
      this.log('on STALLED', event);
    });

    this.on('waiting', (event) => {
      this.log('on WAITING', event);
    });
  }

  setSyncStrategy(strategy) {
    this.destroySyncStrategy();
    this.log('SET SYNC STRATEGY', strategy);
    this.syncStrategy = strategy;
    this.syncStrategy.init(this);
  }

  destroySyncStrategy() {
    this.log('DESTROY SYNC STRATEGY', this.syncStrategy);
    if (this.syncStrategy) {
      this.syncStrategy.destroy();
      this.syncStrategy = null;
    }
  }

  isPaused() {
    return this.originalPlayer.paused;
  }

  // from button control
  setIsPlaying(isPlaying) {
    this.log('setIsPlaying from button control: ', isPlaying);
    this.log('IS PLAYING NOW: ', this.isPlaying);
    if (this.isPlaying === isPlaying) return;
    this.isPlaying = isPlaying;
    if (this.isPlaying) {
      // on iOS the unmuted player can be started
      // only if it's the result of user action,
      // so we should start playback once the user clicked the button
      // https://webkit.org/blog/6784/new-video-policies-for-ios/
      const playImmediately = isIOS;
      if (!playImmediately) this.pause();
      else this.play();
      this.syncStrategy.handleTimeUpdate(true);
    } else this.pause();
  }

  play() {
    this.log('PLAY');
    const promise = this.originalPlayer.play();

    if (promise !== undefined) {
      promise.then(() => {
        // Auto-play started
        this.log('Auto-play started');
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(this.playerId, 'Auto-play was prevented', error);
        // Auto-play was prevented
        // Show a UI element to let the user manually start playback
        if (isIOS && error.name === 'NotAllowedError') {
          this.handleNotAllowedError();
        }
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleNotAllowedError() {
    // do nothing, should be overriden
  }

  pause() {
    this.log('PAUSE');
    this.originalPlayer.pause();
  }

  on(eventName, callback) {
    this.originalPlayer.addEventListener(eventName, callback);
  }

  off(eventName, callback) {
    this.originalPlayer.removeEventListener(eventName, callback);
  }

  getCurrentTime() {
    return this.originalPlayer.currentTime;
  }

  setCurrentTime(value) {
    this.originalPlayer.currentTime = value;
  }

  playIfAllowed() {
    if (this.isPlaying) this.play();
  }

  get() {
    return this.originalPlayer;
  }

  // eslint-disable-next-line no-unused-vars
  log(...args) {
    // eslint-disable-next-line no-console
    if (this.enableLogging) console.log(this.playerId, ...args);
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  trigger(eventName) {
    this.log('TRIGGER', eventName);
    // do nothing, should be overriden
  }

  unmute() {
    this.log('UNMUTE');
    // do nothing, should be overriden
  }

  mute() {
    this.log('MUTE');
    // do nothing, should be overriden
  }

  dispose() {
    this.log('DISPOSE');
    this.destroySyncStrategy();
  }

  isDisposed() {
    return !this.originalPlayer || this.originalPlayer.isDisposed();
  }
}
