import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Ticket from '../../api/services/Ticket';

function TicketValidationForm({ onActivate, episodeId }) {
  const [activationCode, setActivationCode] = useState('');
  const [isValid, setIsValid] = useState();

  useEffect(() => {
    if (isValid) onActivate();
  }, [isValid]);

  const handleChange = (event) => {
    setActivationCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationInfo = await Ticket.validate(activationCode, episodeId);

    if (validationInfo.isValid) {
      Ticket.updateValidatedEpisodesData(validationInfo);
    }

    setIsValid(validationInfo.isValid);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={activationCode}
          onChange={handleChange}
          placeholder="Activation code"
        />

        <input
          type="submit"
          value="Validate ticket"
          disabled={!activationCode}
        />
      </form>
      {isValid !== undefined && (
        <div>
          {isValid
            ? 'Activation code is valid'
            : 'Activation code is not valid'}
        </div>
      )}
    </div>
  );
}
export default TicketValidationForm;

TicketValidationForm.propTypes = {
  onActivate: PropTypes.func,
  episodeId: PropTypes.string,
};

TicketValidationForm.defaultProps = {
  onActivate: () => {},
  episodeId: null,
};
