import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from './pages';

function Router() {
  const pageRoutes = routes.map(({ path, title, element }) => <Route key={title} path={`/${path}`} element={element} />);

  return <Routes>{pageRoutes}</Routes>;
}

export default Router;
